import * as React from "react";

import BlackHeaderBar from "./BlackHeaderBar";
import {Trans, useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";

interface RiskAssessmentSectionProps {
    subsections: Subsection[];
}

interface Subsection {
    name: string;
    values: ValueAndBackgroundColor[];
}

interface ValueAndBackgroundColor {
    value: string;
}

interface HeaderAndValue extends ValueAndBackgroundColor {
    header: string;
    legend: string;
}

const RiskAssessmentSection = ({
                                   subsections
                               }: RiskAssessmentSectionProps) => {
    const {t} = useTranslation("common");

    const createSubSection = (
        headersAndValues: HeaderAndValue[],
        withHorizontalLine?: boolean
    ) => {
        return (
            <>
                {headersAndValues.map((x) => (

                    <CRow>
                        <CCol xs="12" md="8" className="header mb-md-2">
                            <Trans
                                t={t}
                                i18nKey={x.header}
                            >Header (<i>S</i><sub>I</sub>) <sup>x.x</sup></Trans>
                            <br/>
                            {x.legend && <small><Trans
                                t={t}
                                i18nKey={x.legend}
                            >Value <i>S</i><sub>I</sub><sup>y.y</sup></Trans></small>}
                        </CCol>
                        <CCol xs="12" md="4" className="value mb-2">{x.value}</CCol>
                    </CRow>
                ))}
                {withHorizontalLine && <hr className="mb-4"/>}
            </>
        );
    };

    return (
        <div>

            <BlackHeaderBar
                text={<Trans t={t} i18nKey="step4.riskAssessment.headerText">Header <sup>11.1</sup></Trans>}
                urlToNavigate="/step1"
                hasButton
            />

            <div className="px-3 pt-3 header">
                {t("step4.riskAssessment.introText")}
            </div>

            <div className="p-3">
                {subsections.map((subsection, subsectionIndex) => (
                    <>
                        <CRow>
                            <CCol>
                                <h3 className="mb-3">

                                    {t(`step4.riskAssessment.${subsection.name}.headerText`)}

                                </h3>
                            </CCol>
                        </CRow>
                        {createSubSection(
                            subsection.values.map((x, valueIndex) => ({
                                header:
                                    `step4.riskAssessment.${subsection.name}.textRows.${valueIndex}`
                                ,
                                legend: `step4.riskAssessment.${subsection.name}.legends.${valueIndex}`,
                                value: x.value
                            })),
                            subsectionIndex + 1 !== subsections.length
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default RiskAssessmentSection;
