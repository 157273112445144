import * as React from "react";
import BlackHeaderBar from "./BlackHeaderBar";
import {Trans, useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";

interface WeighingProcessSectionProps {
    weight: string;
    isRequiringEquipmentQualification: string;
    isRequiringUSPharmaCompliance: string;
    isRequiringEUPharmaCompliance: string;
    isBalanceUsedForTest: string;
    isRequiringFullMaintenance: string;
    requiredProcessAccuracy: string;
    safetyFactor: string;
}

const WeighingProcessSection = (
    props: WeighingProcessSectionProps
) => {
    const {t} = useTranslation("common");

    return (
        <div>
            <div>
                <BlackHeaderBar
                    text={<Trans t={t} i18nKey="step4.weighingProcess.headerText">Header <sup>11.1</sup></Trans>}
                    urlToNavigate="/step2"
                    hasButton
                />
                <div className="p-3">

                    {[
                        "weight",
                        "isRequiringEquipmentQualification",
                        "isRequiringUSPharmaCompliance",
                        "isRequiringEUPharmaCompliance",
                        "isBalanceUsedForTest",
                        "isRequiringFullMaintenance"
                    ].map((x) => (
                        <CRow className="mb-2">
                            <CCol lg={8} className="header">
                                {t(`step4.weighingProcess.firstSection.${x}`)}
                            </CCol>
                            <CCol lg={4} className="value">{props[x]}</CCol>
                        </CRow>
                    ))}

                    <hr className="mt-5"/>
                    <div>

                        <h4 className={`mt-6`}>
                            {t(`step4.weighingProcess.secondSection.headerText`)}
                        </h4>

                        {["requiredProcessAccuracy", "safetyFactor"].map((x) => (
                            <CRow className="mb-1">
                                <CCol lg={8}>
                                    <div className="header">
                                        <Trans
                                            t={t}
                                            i18nKey={`step4.weighingProcess.secondSection.${x}`}
                                        >Text <i>Header</i> <sup>x.x</sup></Trans>
                                    </div>
                                </CCol>
                                <CCol lg={4}>
                                    <div
                                        className="value"
                                    >
                                        {props[x]}
                                    </div>
                                </CCol>
                            </CRow>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeighingProcessSection;
