import {CRow, CCol} from "@coreui/react";
import React from "react";

export const Legend = () => {
    return <>
        <CRow>
            <CCol xs={1}>1</CCol>
            <CCol>
                The risk analysis documents the customer's assessment of the impact and the probability of incorrect
                measurement results in the customer-specific weighing process. The scores derived from this
                assessment are taken to calculate the impact factor (<i>F</i><sub>I</sub>), probability factor
                (<i>F</i><sub>P</sub>) and the risk indicator (<i>R</i>).
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>1.1</CCol>
            <CCol>
                The impact factor (<i>F</i><sub>I</sub>) is determined from the maximum value of the individual impact
                types.

            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>1.2</CCol>
            <CCol>
                The probability factor (<i>F</i><sub>P</sub>) is calculated as the product of the “probability that
                incorrect measurements occur” and the “probability that incorrect results stay undetected”.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>1.3</CCol>
            <CCol>
                The overall risk of the weighing process is expressed in a risk indicator (<i>R</i>) and
                is calculated as the product of <i>F</i><sub>I</sub> and <i>F</i><sub>P</sub>.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>2</CCol>
            <CCol>
                The Sartorius recommendations for the process accuracy (<i>PA</i>) and safety factor (<i>SF</i>) result
                from the risk assessment and the customer requirements for the weighing process.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>2.1</CCol>
            <CCol>
                <strong>Process accuracy</strong>: If the device is used under USP &lt;41&gt; or Ph.Eur. 2.1.7 or if it
                is unclear if these requirements are relevant, a process accuracy of 0.1 % is recommended. If neither
                the USP &lt;41&gt; nor the Ph.Eur. 2.1.7. is relevant, the recommended process accuracy is derived from
                the impact factor (<i>F</i><sub>I</sub>) according to the following system:
            </CCol>
        </CRow>
        <CRow className="mt-2">
            <CCol xs={1}></CCol>
            <CCol></CCol>
            <CCol><i>F</i><sub>I</sub> = 1</CCol>
            <CCol><i>F</i><sub>I</sub> = 2</CCol>
            <CCol><i>F</i><sub>I</sub> = 3</CCol>
            <CCol><i>F</i><sub>I</sub> = 5</CCol>
            <CCol><i>F</i><sub>I</sub> = 6</CCol>
            <CCol><i>F</i><sub>I</sub> = 10</CCol>
        </CRow>
        <CRow className="mb-3">
            <CCol xs={1}></CCol>
            <CCol><i>PA</i></CCol>
            <CCol>5%</CCol>
            <CCol>2%</CCol>
            <CCol>1%</CCol>
            <CCol>0.5%</CCol>
            <CCol>0.2%</CCol>
            <CCol>0.1%</CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>2.2</CCol>
            <CCol>
                <strong>Safety Factor</strong>: If the device is under USP &lt;41&gt; or Ph.Eur. 2.1.7. or if it is
                unclear if these requirements are relevant, a safety factor of 1 is recommended. If neither the
                USP &lt;41&gt; nor the Ph.Eur. 2.1.7. is relevant, the recommended safety factor is derived from the
                probability factor (<i>F</i><sub>P</sub>) according to the following system:
            </CCol>
        </CRow>
        <CRow className="mt-2">
            <CCol xs={1}></CCol>
            <CCol></CCol>
            <CCol><i>F</i><sub>P</sub> = 1</CCol>
            <CCol><i>F</i><sub>P</sub> = 2</CCol>
            <CCol><i>F</i><sub>P</sub> = 3</CCol>
            <CCol><i>F</i><sub>P</sub> = 4-6</CCol>
            <CCol><i>F</i><sub>P</sub> = 9</CCol>
        </CRow>
        <CRow className="mb-3">
            <CCol xs={1}></CCol>
            <CCol><i>SF</i></CCol>
            <CCol>1</CCol>
            <CCol>2</CCol>
            <CCol>3</CCol>
            <CCol>5</CCol>
            <CCol>10</CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>3.</CCol>
            <CCol>
                The recommended services and certificates are derived from the customer's process requirements and
                regulatory requirements.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>3.1</CCol>
            <CCol>
                The recommended service frequency is derived from the risk indicator (<i>R</i>) according to the
                following system:
                SR ≤ 6 "Every second year"; <i>R</i> 8 - 15 “yearly”, <i>R</i> 18 - 30 "twice a year";
                <i>R</i> &gt;35 “every 3 month”.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.</CCol>
            <CCol>
                The recommendations for routine testing are derived from the customer's process requirements and
                regulatory requirements.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.1</CCol>
            <CCol>
                <strong>Internal adjustment</strong>: It is recommended to activate the internal adjustment function
                whenever the
                balance
                one.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2</CCol>
            <CCol>
                One-point calibration with external test weights:
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.1</CCol>
            <CCol>
                The recommended test frequency is derived from the risk indicator (<i>R</i>). For balances with
                internal adjustment function: <i>R</i> ≤ 6 "Monthly"; <i>R</i> 8 - 15 “every second week”,
                <i>R</i> 18 - 30 "weekly"; <i>R</i> &gt;35
                “daily”.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.2</CCol>
            <CCol>
                The recommended test weight is given as the next single piece test load around 80% of the maximum
                capacity of the first weighing range.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.3</CCol>
            <CCol>
                The recommended test weight class respects the scale interval (<i>d</i>) and a test weight error
                (MPE) ≤ ⅒ of the warning tolerance limit. If this requirement cannot be met with E2 Class weights,
                a test against the conventional weight value is recommended.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.4</CCol>
            <CCol>
                The recommended calibration cycle for the test weight results from the risk indicator (<i>R</i>).
                <i>R</i> &lt; 55, "Every second year"; <i>R</i> ≥ 55, "Yearly"
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.5</CCol>
            <CCol>
                The recommended action tolerance limit is derived from the defined process accuracy (<i>PA</i>) divided
                by the safety factor (<i>SF</i>). If Ph.Eur. 2.1.7 is relevant or if it is unclear if it is relevant the
                safety factor is doubled which results in a <i>PA</i> of 0,05 %.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.2.6</CCol>
            <CCol>
                The recommended warning tolerance limit is half the action tolerance limit.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3</CCol>
            <CCol>
                <strong>Repeatability test with external test weights</strong>:
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.1</CCol>
            <CCol>
                The recommended test frequency is derived from the risk indicator (<i>R</i>). For balances with
                internal adjustment function: <i>R</i> ≤ 6 "Monthly"; <i>R</i> 8 - 15 “every second week”,
                <i>R</i> 18 - 30 "weekly"; <i>R</i> &gt;35 “daily”.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.2</CCol>
            <CCol>
                The recommended test weight is based on the smallest net weight that should be weight on the balance,
                the recommended weight is the next single piece test load around this value but at least 100 mg.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.3</CCol>
            <CCol>
                The recommended test weight class is given with the same class as for the One-point calibration.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.4</CCol>
            <CCol>
                The recommended calibration cycle for the test weight results from the risk indicator (<i>R</i>).
                <i>R</i> &lt; 55, "Every second year"; <i>R</i> ≥ 55, "Yearly".
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.5</CCol>
            <CCol>
                The recommended action tolerance limit is derived from the smallest net weight and the necessary
                process accuracy (<i>PA</i>) with an expansion factor of 2.
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={1}>4.3.6</CCol>
            <CCol>
                The recommended warning tolerance limit is half the action tolerance limit.
            </CCol>
        </CRow>
        <p className="mt-8">
            <strong>Disclaimer</strong><br/>
            All information and recommendations in this document are based on the customer's information about the
            process risks and process requirements. All statements are for information only and are not binding in any
            way. Defining, checking and approval of all test values, test frequencies, tolerances etc. are in the
            responsibility of the device user and may need to take additional customer-specific factors into account.
        </p>
    </>;
};