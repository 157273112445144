import {atom} from "recoil";
import {Configuration} from "./types/Configuration";

export const showNavigationState = atom<boolean>({
    key: "showNavigation",
    default: true
});

export const currentStepState = atom<number>({
    key: "currentStep",
    default: 1
});

export const isRequestSubmittedState = atom<boolean>({
    key: "isRequestSubmitted",
    default: false
});

export const isFeedbackSentState = atom<boolean>({
    key: "isFeedbackSent",
    default: false
});

export const quoteIdState = atom<number | undefined>({
    key: "quoteId",
    default: undefined
});

export const configurationState = atom<Configuration>({
    key: "configuration",
    default: {
        step1Data: {
            isCompleted: false
        },
        step2Data: {
            isCompleted: false,
            weightType: "g"
        },
        step3Data: {
            isCompleted: false
        }
    }
});

export const modelState = atom<string>({
    key: "model",
    default: ""
});