import {CCollapse} from "@coreui/react";
import * as React from "react";
import {useState} from "react";
import styles from "./CollapsibleRow.module.scss";

interface AccordionElementProps {
    accordionClassName?: string;
    children: any;
    customComponent?: any;
    headerKey?: string;
    onClick?: any;
    showInitially?: boolean;
    mainText: string;
    rightText?: string;
}

const CollapsibleRow = (
    props: AccordionElementProps
) => {
    const {
        children,
        customComponent,
        headerKey,
        onClick,
        showInitially = false,
        mainText,
        rightText
    } = props;
    const [show, setShow] = useState<boolean>(showInitially);
    const toggle = () => {
        setShow(!show);
    };

    return (
        <div
            className={`${styles.accordion} accordion-element`}
        >
            <div
                onClick={() => {
                    toggle();
                    onClick && onClick();
                }}
                role="button"
                tabIndex={0}
                className={`${styles.row} row flex-nowrap ${
                    show ? "text-dark" : styles.collapsedRow
                }`}
            >
                <span className={styles.mainText}>{mainText}</span>
                {customComponent ? (
                    <div className={styles.content}>{customComponent}</div>
                ) : (
                    <h3 className={`${styles.content} flex-grow-1 align-self-center`}>
                        {headerKey}
                    </h3>
                )}
                <div className={styles.rightText}>
                    <strong>{rightText}</strong>
                </div>
                <div className={styles.arrowWrapper}>
                    <i className={`${styles.arrow} h1 icon-${show ? "up" : "down"}`}/>
                </div>
            </div>
            <div
                className={`${
                    show ? styles.collapsibleContent : styles.collapsedContent
                } `}
            >
                <CCollapse show={show}>{children}</CCollapse>
            </div>
        </div>
    );
};

export default CollapsibleRow;
