import * as React from "react";
import styles from "./RecommendationRoutineSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import {Trans, useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";

type RecommendationRoutineSectionProps = {
    subsections: Subsection[];
}

type Subsection = {
    name: string;
    values: ValueAndBackgroundColor[];
}

type ValueAndBackgroundColor = {
    value: string;
    isValueBackgroundGray?: boolean;
}

type HeaderAndValue = ValueAndBackgroundColor & {
    header: JSX.Element;
}

const Subsection = ({headersAndValues}: {
    headersAndValues: HeaderAndValue[]
}) => {

    return (
        <>
            <div>
                <CRow>
                    {headersAndValues.map((x) => (<React.Fragment key={`${x.header}+${x.value}`}>
                        <CCol xs="12" lg="8">
                            <div className="header">{x.header}</div>
                        </CCol>
                        <CCol xs="12" lg="4">
                            <div className={`mb-1 value ${
                                x.isValueBackgroundGray ? styles.withGrayBackground : ""
                            }`}>{x.value}</div>
                        </CCol>
                    </React.Fragment>))}
                </CRow>
            </div>
        </>
    );
};

const RecommendationRoutineSection = ({subsections}: RecommendationRoutineSectionProps) => {
    const {t} = useTranslation("common");

    return (
        <div>
            <div className={styles.blackHeaderWrapper}>
                <BlackHeaderBar text={<Trans t={t}
                                             i18nKey="step4.recommendationRoutine.headerText">Header <sup>1.1</sup></Trans>}/>
            </div>
            <div className="p-3">
                {subsections.map((subsection, subsectionIndex) => (
                    <>
                        <h4 className={styles.sectionTitle}>
                            <Trans t={t}
                                   i18nKey={`step4.recommendationRoutine.${subsection.name}.headerText`}>Header <sup>1.1</sup></Trans>
                        </h4>
                        <Subsection headersAndValues={subsection.values.map((x, valueIndex) => ({
                            header: <Trans t={t}
                                           i18nKey={`step4.recommendationRoutine.${subsection.name}.textRows.${valueIndex}`}>Header <sup>1.1</sup></Trans>,
                            value: x.value,
                            isValueBackgroundGray: x.isValueBackgroundGray
                        }))}/>
                        {subsectionIndex + 1 !== subsections.length && <hr/>}
                    </>
                ))}
            </div>
        </div>
    );
};

export default RecommendationRoutineSection;
