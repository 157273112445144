import * as React from "react";
import styles from "./LifecycleServices.module.scss";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";
import {GreyRow} from "./InitialServices";

interface LifecycleServicesProps {
    serviceFrequency: string;
    equipmentMaintenance: boolean;
    calibrationCertificate: boolean;
    minWeightCertificate: boolean;
    conformityCertificate: boolean;
    calibration: boolean;
    usp: boolean;
    phEur: boolean;
    balanceConformityCertificate: string;
    balanceTestProtocol: string;
    repeatabilitMeasurements: string;
    checkOfConformity: string;
    model: string;
    isBasicPackage: boolean;
    isTestPippette: boolean;
}

const LifecycleServices = (props: LifecycleServicesProps) => {
    const {t} = useTranslation("common");

    const rows = [
        "serviceFrequency",
        "equipmentMaintenance",
        "calibrationCertificate",
        "minWeightCertificate",
        "conformityCertificate",
        "calibration",
        "usp",
        "phEur"
    ];

    return (
        <>
            <CollapsibleRow
                mainText={t(
                    `step4.recommendedService.secondSection.${
                        props.isBasicPackage
                            ? "pharmaMaintenancePackageBasic"
                            : "pharmaMaintenancePackage"
                    }`
                )}
                rightText={`${t("step4.recommendedService.orderNo")}: ${props.model}`}
                showInitially={true}
                children={
                    <div className={styles.section}>
                        {rows
                            .filter((x) => props[x] !== undefined)
                            .map((x, index) =>
                                <GreyRow key={index} text={
                                    t(`step4.recommendedService.${x}.text`)} checkmark={
                                    props[x]} isNested={
                                    i18next.exists(
                                        `common:step4.recommendedService.${x}.isNested`
                                    )} isLastRow={
                                    index === rows.length - 1
                                }/>
                            )}
                    </div>
                }
            />

            <CollapsibleRow
                mainText={t(`step4.recommendedService.optionalServices.text`)}
                rightText={`${t("step4.recommendedService.orderNo")}:`}
                showInitially={true}
                children={
                    <>{props.balanceConformityCertificate &&
                        <GreyRow text={t(`step4.recommendedService.balanceConformityCertificate.text`)}
                                 value={props.balanceConformityCertificate}/>
                    }
                        <GreyRow text={t(`step4.recommendedService.balanceTestProtocol.text`)}
                                 value={props.balanceTestProtocol}/>
                        <GreyRow text={t(`step4.recommendedService.repeatabilitMeasurements.text`)}
                                 value={props.repeatabilitMeasurements}/>
                        {props.checkOfConformity &&
                            <GreyRow text={t(`step4.recommendedService.checkOfConformity.text`)}
                                     value={props.checkOfConformity}/>}
                    </>
                }
            />

        </>
    );
};

export default LifecycleServices;
