import * as React from "react";
import BlackHeaderBar from "./BlackHeaderBar";
import {useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";
import {SftConfiguration} from "../../api";

const weighingRangeMinLength = 3;

interface SpecifiedInstrumentSectionProps {
    model: string;
    manufacturer: string;
    type: string;
    isoCalFunction: string;
    secondSubsection: SftConfiguration[];
}

const SpecifiedInstrumentSection = ({
                                        model,
                                        manufacturer,
                                        type,
                                        isoCalFunction,
                                        secondSubsection
                                    }: SpecifiedInstrumentSectionProps) => {
    const {t} = useTranslation("common");

    const weighingRanges = secondSubsection.concat(Array(weighingRangeMinLength).fill({})).slice(0, Math.max(secondSubsection.length, weighingRangeMinLength));


    return (
        <div className="mb-4">
            <BlackHeaderBar
                text={t("step4.specifiedInstrument.headerText")}
                urlToNavigate="/step2"
                hasButton
            />
            <div className="p-3">
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.model")}
                    </CCol>
                    <CCol sm={9} className="value">{model}</CCol>
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.manufacturer")}
                    </CCol>
                    <CCol sm={9} className="value">{manufacturer}</CCol>
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.type")}
                    </CCol>
                    <CCol sm={9} className="value">{t("step4.specifiedInstrument.typeValue." + type)}</CCol>
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.isoCalFunction")}
                    </CCol>
                    <CCol sm={9} className="value">{isoCalFunction}</CCol>
                </CRow>
                <hr className="my-4"/>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.weighingRange")}
                    </CCol>
                    {weighingRanges.map((wr) =>
                        <CCol className="value">{wr.weighingRange}</CCol>
                    )}
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.maxg")}
                    </CCol>
                    {weighingRanges.map((wr) =>
                        <CCol className="value">{wr.maxWeight}</CCol>
                    )}
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.scaleInterval")}
                    </CCol>
                    {weighingRanges.map((wr) =>
                        <CCol className="value">{wr.scaleInterval}</CCol>
                    )}
                </CRow>
                <CRow className="mb-1">
                    <CCol sm={3} className="header">
                        {t("step4.specifiedInstrument.verificationScaleInterval")}
                    </CCol>
                    {weighingRanges.map((wr) =>
                        <CCol className="value">{wr.verificationScaleInterval}</CCol>
                    )}
                </CRow>
            </div>
        </div>
    );
};

export default SpecifiedInstrumentSection;
