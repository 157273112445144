import * as React from "react";
import BlackHeaderBar from "./BlackHeaderBar";
import {useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";

interface CustomerDataSectionProps {
    company: string;
    streetAndAddressNumber: string;
    zipCodeAndCity: string;
    country: string;
    department?: string;
    customerContact: string;
}

const CustomerDataSection = ({
                                 company,
                                 country,
                                 customerContact,
                                 streetAndAddressNumber,
                                 zipCodeAndCity,
                                 department
                             }: CustomerDataSectionProps) => {
    const {t} = useTranslation("common");

    return (
        <>
            <BlackHeaderBar
                text={t("step4.customerData.headerText")}
                urlToNavigate="/step3"
                hasButton
            />
            <CRow className="p-3">
                <CCol md="6" className="mb-2">
                    <div className="header">{t("step4.customerData.company")}</div>
                    {company}<br/>
                    {streetAndAddressNumber}<br/>
                    {zipCodeAndCity}<br/>
                    {country}
                </CCol>
                <CCol md="6">
                    <div className="header">{t("step4.customerData.customerContact")}</div>
                    {customerContact}<br/>
                    {department}
                </CCol>
            </CRow>
        </>
    );
};

export default CustomerDataSection;
