import * as React from "react";
import styles from "./InitialServices.module.scss";
import {useTranslation} from "react-i18next";
import Check from "../../assets/icons/check.svg";
import i18next from "i18next";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";

interface InitialServicesProps {
    iqOqDocumentation?: boolean;
    equipmentInstallation: boolean;
    calibrationCertificate: boolean;
    minWeightCertificate: boolean;
    conformityCertificate: boolean;
    calibration: boolean;
    usp: boolean;
    phEur: boolean;
    model: string;
    isTestPippette: boolean;
    balanceConformityCertificate: string;
    balanceTestProtocol: string;
    repeatabilityMeasurements: string;
}

export const GreyRow = ({text, value, checkmark, isNested, isLastRow}: {
    text: string,
    value?: string,
    checkmark?: boolean
    isNested?: boolean,
    isLastRow?: boolean
}) => {
    return <div className={styles.serviceRow}>
        <div className={`${isNested ? styles.nested : ""}`}>
            {text}
        </div>
        <div
            className={`${styles.value} ${isLastRow ? styles.lastRow : ""}`}
        >
            <div>
                {typeof checkmark !== "undefined" ? checkmark ? <img src={Check} alt="checkmark"/> :
                    <div className={styles.none}>-</div> : ""}
                {value ? value : ""}
            </div>
        </div>
    </div>;
};

const InitialServices = (props: InitialServicesProps) => {
    const {t} = useTranslation("common");

    const rows = [
        "equipmentInstallation",
        "iqOqDocumentation",
        "calibrationCertificate",
        "minWeightCertificate",
        "conformityCertificate",
        "calibration",
        "usp",
        "phEur"
    ];

    const isPharmaInstallationPackage = props.iqOqDocumentation === undefined;
    const rowsLastIndex = isPharmaInstallationPackage
        ? rows.length - 2
        : rows.length - 1;

    return (
        <>
            <CollapsibleRow
                mainText={t(
                    `step4.recommendedService.firstSection.${
                        isPharmaInstallationPackage
                            ? "pharmaInstallationPackage"
                            : "equipmentQualificationPackage"
                    }`
                )}
                rightText={`${t("step4.recommendedService.orderNo")}: ${props.model}`}
                showInitially={true}
                children={
                    <div className={styles.section}>
                        {rows
                            .filter((x) => props[x] !== undefined)
                            .map((x, index) =>
                                <GreyRow key={index} text={
                                    t(`step4.recommendedService.${x}.text`)} checkmark={
                                    props[x]} isNested={
                                    i18next.exists(
                                        `common:step4.recommendedService.${x}.isNested`
                                    )} isLastRow={
                                    index === rowsLastIndex
                                }/>
                            )
                        }
                    </div>
                }
            />

            <CollapsibleRow
                mainText={t(`step4.recommendedService.optionalServices.text`)}
                rightText={`${t("step4.recommendedService.orderNo")}:`}
                showInitially={true}
                children={
                    <div className={styles.section}>
                        {props.balanceConformityCertificate &&
                            <GreyRow text={t(`step4.recommendedService.balanceConformityCertificate.text`)}
                                     value={props.balanceConformityCertificate}
                            />
                        }
                        <GreyRow text={t(`step4.recommendedService.balanceTestProtocol.text`)}
                                 value={props.balanceTestProtocol}
                        />
                        <GreyRow text={t(`step4.recommendedService.repeatabilitMeasurements.text`)}
                                 value={props.repeatabilityMeasurements}
                        />
                    </div>
                }
            />
        </>

    );
};

export default InitialServices;
