import {
    CCol,
    CContainer,
    CHeader,
    CHeaderBrand,
    CImg,
    CLink,
    CNav,
    CRow,
    CSubheader
} from "@coreui/react";
import React from "react";
import sartoriusLogo from "../sartorius-logo.svg";
import styles from "./Header.module.scss";
import NavigationSteps from "./Navigation/NavigationSteps";
import NavigationTitle from "./Navigation/NavigationTitle";
import {useTranslation} from "react-i18next";
import LanguageDropdown from "./Dropdowns/LanguageDropdown";
import {UseReactToPrintFn} from "react-to-print";

interface HeaderProps {
    showNavigation: boolean;
    isSuccessPage: boolean;
    isViewPage: boolean;
    currentStep: number;
    printFn: UseReactToPrintFn;
}

const Header: React.FC<HeaderProps> = ({
                                           showNavigation,
                                           currentStep,
                                           isViewPage,
                                           isSuccessPage,
                                           printFn
                                       }) => {
    const {t} = useTranslation("common");

    return (
        <CHeader data-testid="header" withSubheader={true}>
            <CContainer className={styles.headerContainer}>
                <div>
                    <CHeaderBrand className="mr-auto">
                        <CLink
                            href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii"
                            target="_blank"
                        >
                            <CImg
                                data-testid="logo"
                                src={sartoriusLogo}
                                alt="Sartorius"
                            />
                        </CLink>
                    </CHeaderBrand>
                </div>
                <CNav className={styles.navContainer + " d-print-none"}>
                    {!isViewPage && <LanguageDropdown/>}
                </CNav>
            </CContainer>

            {!isViewPage && (
                <CSubheader className="border-0 text-black" data-testid="subheader">
                    <CContainer>
                        <CRow>
                            <CCol lg="12">
                                <h2 className="mt-3">{t("step1.subheader")}</h2>
                            </CCol>
                        </CRow>
                    </CContainer>
                </CSubheader>
            )}
            {!isSuccessPage && !isViewPage && showNavigation && (
                <>
                    <>
                        <CSubheader className="border-0 d-print-none">
                            <CContainer className="my-1">
                                <NavigationSteps currentStep={currentStep}/>
                            </CContainer>
                        </CSubheader>
                        {!isSuccessPage && (
                            <CSubheader className="border-0 pb-2 d-print-none">
                                <CContainer>
                                    <NavigationTitle currentStep={currentStep} printFn={printFn}/>
                                </CContainer>
                            </CSubheader>
                        )}
                    </>
                </>
            )}
        </CHeader>
    );
};

export default Header;
