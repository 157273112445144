import {CButton} from "@coreui/react";
import React from "react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import styles from "./NavigationButtons.module.scss";

interface NavigationButtonProps {
    onClick: () => void;
    label: string;
    height: number;
    width: number;
}

export const NavigationButtonDownload: React.FC<NavigationButtonProps> = ({
                                                                              onClick,
                                                                              height,
                                                                              width,
                                                                              label
                                                                          }): JSX.Element => {

    return (
        <CButton
            data-testid="next-button"
            shape="square"
            color={"dark"}
            variant={"outline"}
            className={`${styles.navigationButton} border border-dark d-print-none`}
            onClick={(e: MouseEvent) => {
                onClick();
                (e.currentTarget as HTMLElement).blur();
            }}
        >
            {label}{" "}
            <img
                src={DownloadIcon}
                width={width}
                height={height}
                alt={label}
                title={label}
            />
        </CButton>
    );
};

export const NavigationButtonNext: React.FC<NavigationButtonProps> = ({
                                                                          label,
                                                                          onClick,
                                                                          height,
                                                                          width
                                                                      }): JSX.Element => {
    return (
        <CButton
            data-testid="next-button"
            shape="square"
            color={"dark"}
            variant={"outline"}
            className={`${styles.navigationButton} border border-dark d-print-none`}
            onClick={(e: MouseEvent) => {
                onClick();
                (e.currentTarget as HTMLElement).blur();
            }}
        >
            {label}{" "}
            <img
                src={ArrowRightIcon}
                width={width}
                height={height}
                alt={label}
                title={label}
            />
        </CButton>
    );
};

export const NavigationButtonPrevious: React.FC<NavigationButtonProps> = ({
                                                                              onClick,
                                                                              label,
                                                                              width,
                                                                              height
                                                                          }): JSX.Element => {
    return (
        <CButton
            shape="square"
            color="dark"
            variant="outline"
            className={`${styles.navigationButton} border border-dark d-print-none`}
            onClick={onClick}
            data-testid="back-button"
        >
            <img
                src={ArrowLeftIcon}
                width={width}
                height={height}
                alt={label}
                title={label}
            />
            {label}
        </CButton>
    );
};
