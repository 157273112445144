import {NavigationWarning} from "../components/Navigation/NavigationWarning";
import {useRecoilState} from "recoil";
import {useEffect, useState} from "react";
import {configurationState, currentStepState, modelState} from "../states";
import {useHistory} from "react-router-dom";
import CustomerDataSection from "../components/Recommendation/CustomerDataSection";
import SpecifiedInstrumentSection from "../components/Recommendation/SpecifiedInstrumentSection";
import RiskAssessmentSection from "../components/Recommendation/RiskAssessmentSection";
import WeighingProcessSection from "../components/Recommendation/WeighingProcessSection";
import RecommendedServiceSection from "../components/Recommendation/RecommendedServiceSection";
import RecommendationRoutineSection from "../components/Recommendation/RecommendationRoutineSection";
import {useAssessment} from "../hooks/useAssessmentApi";
import {
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CSpinner
} from "@coreui/react";
import {AssessmentDataCalculatedDTO} from "../api";
import {trackPageImpression} from "../services/googleTagManager";
import {UseReactToPrintFn} from "react-to-print";
import {Legend} from "../components/Recommendation/Legend";

const Step4 = ({printFn}: { printFn: UseReactToPrintFn }) => {
    const history = useHistory();
    const [configuration] = useRecoilState(configurationState);
    const [, setModel] = useRecoilState(modelState);
    const [assessment, setAssessment] = useState<AssessmentDataCalculatedDTO>(null);
    const [, setCurrentStep] = useRecoilState(currentStepState);
    const {getAssessment} = useAssessment();
    const [, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        if (!configuration.step3Data.isCompleted) {
            history.push("/step3");
            return;
        }
        const fetchAssessment = async () => {
            setIsLoading(true);
            try {
                const res = await getAssessment();
                setAssessment(res);
                setModel(res.pageOneDTO.instrumentConfigurations.instrumentConfigurations[0].model);
            } catch (error) {
                console.log("Error fetching assessment:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (!assessment) {
            fetchAssessment();
            trackPageImpression(4);
        }

        setCurrentStep(4);
    }, [assessment]);

    const toggleShowModal = () => {
        setShowModal(!showModal);
    };
    const fetchPdf = async () => {
        toggleShowModal();
        setTimeout(() => {
            printFn();
        }, 100);

    };

    return (
        <>
            <CModal
                show={showModal}
                onClose={toggleShowModal}
                centered={true}
                size=""
                scrollable
            >
                <CModalHeader data-testid="help-modal-header" closeButton>
                    <CModalTitle data-testid="help-modal-title">
                        Do you want to download the PDF to your device?
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div>
                        <p>
                            You can download the PDF now or review your information and
                            download it later.
                        </p>
                    </div>
                    <div className="d-flex flex-col align-items-between">
                        <CButton
                            onClick={fetchPdf}
                            // style={}
                            shape="square"
                            active={true}
                            color={showModal ? "primary" : ""}
                            variant={"outline"}
                            className={`border border-dark flex-grow-1 px-6 mr-6`}
                            data-testid="help-button"
                        >
                            Yes
                        </CButton>
                        <CButton
                            onClick={toggleShowModal}
                            // style={}
                            shape="square"
                            color={showModal ? "dark " : ""}
                            variant={"outline"}
                            className={`border border-dark flex-grow-1 pr-6`}
                            data-testid="help-button"
                        >
                            No
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
            <NavigationWarning missingFields={[]}/>
            {assessment ? (
                <>
                    <div className="printSection">
                        <CustomerDataSection
                            company={assessment.pageOneDTO.customerContactData.company}
                            country={assessment.pageOneDTO.customerContactData.country}
                            customerContact={assessment.pageOneDTO.customerContactData.email}
                            streetAndAddressNumber={
                                assessment.pageOneDTO.customerContactData.addressNumber
                                    ? `${assessment.pageOneDTO.customerContactData.street} ${assessment.pageOneDTO.customerContactData.addressNumber}`
                                    : assessment.pageOneDTO.customerContactData.street
                            }
                            zipCodeAndCity={`${assessment.pageOneDTO.customerContactData.zipcode} ${assessment.pageOneDTO.customerContactData.city}`}
                            department={assessment.pageOneDTO.customerContactData.industry}
                        />
                        <SpecifiedInstrumentSection
                            model={
                                assessment.pageOneDTO.instrumentConfigurations
                                    .instrumentConfigurations[0].model
                            }
                            manufacturer={
                                assessment.pageOneDTO.instrumentConfigurations
                                    .instrumentConfigurations[0].manufacturer
                            }
                            type={
                                assessment.pageOneDTO.instrumentConfigurations
                                    .instrumentConfigurations[0].type
                            }
                            isoCalFunction={
                                assessment.pageOneDTO.instrumentConfigurations
                                    .instrumentConfigurations[0].isoCal
                            }
                            secondSubsection={
                                assessment.pageOneDTO.instrumentConfigurations
                                    .instrumentConfigurations
                            }
                        />
                        <RiskAssessmentSection
                            subsections={[
                                {
                                    name: "firstSection",
                                    values: [
                                        {
                                            value: assessment.pageOneDTO.riskAssessment.impactHealthScore
                                        },
                                        {
                                            value: assessment.pageOneDTO.riskAssessment.impactBusinessScore
                                        },
                                        {
                                            value:
                                                assessment.pageOneDTO.riskAssessment.impactScore.toString()
                                        }
                                    ]
                                },
                                {
                                    name: "secondSection",
                                    values: [
                                        {
                                            value: assessment.pageOneDTO.riskAssessment.probabilityOccurrenceScore
                                        },
                                        {
                                            value: assessment.pageOneDTO.riskAssessment.probabilityDetectionScore
                                        },
                                        {
                                            value:
                                                assessment.pageOneDTO.riskAssessment.probabilityScore.toString()
                                        }
                                    ]
                                },
                                {
                                    name: "thirdSection",
                                    values: [
                                        {
                                            value:
                                                assessment.pageOneDTO.riskAssessment.riskAssessmentScore.toString()
                                        }
                                    ]
                                }
                            ]}
                        />
                        {/*<div className="px-3 mt-8 d-none d-print-block">*/}
                        {/*    <div className="header">{t('step4.sig.header')}</div>*/}
                        {/*    <div className="d-flex">*/}
                        {/*        {["date", "name", "signature"].map((x) => (*/}
                        {/*            <div key={x}>*/}
                        {/*                <div className="mr-6">{t(`step4.sig.${x}`)}</div>*/}
                        {/*                <div className="mr-6 mt-6 border" style={{minWidth: "15rem"}}></div>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="printSectionEnd"/>
                    <div className="printSection">
                        <WeighingProcessSection
                            isRequiringEquipmentQualification={
                                assessment.pageTwoDTO.equipmentQualificationNeeded
                            }
                            isRequiringUSPharmaCompliance={
                                assessment.pageTwoDTO.requiresComplianceUSA
                            }
                            weight={assessment.pageTwoDTO.minWeight}
                            isRequiringEUPharmaCompliance={
                                assessment.pageTwoDTO.requiresComplianceEU
                            }
                            isBalanceUsedForTest={assessment.pageTwoDTO.isForTestPipettes}
                            isRequiringFullMaintenance={
                                assessment.pageTwoDTO.fullMaintenanceReqPos
                            }
                            requiredProcessAccuracy={
                                assessment.pageTwoDTO.requiredProcessAccuracy
                            }
                            safetyFactor={assessment.pageTwoDTO.safetyFactor}
                        />
                    </div>
                    <div className="printSectionEnd"/>
                    <div className="printSection">
                        <RecommendedServiceSection
                            config={assessment.pageTwoDTO}
                            initialServices={assessment.pageThreeDTO}
                            lifecycleServices={assessment.pageFourDTO}
                        />
                        <br/>
                    </div>
                    <div className="printSectionEnd"/>
                    <div className="printSection">
                        <RecommendationRoutineSection
                            subsections={[
                                {
                                    name: "firstSection",
                                    values: [
                                        {
                                            value:
                                                assessment.pageOneDTO.instrumentConfigurations
                                                    .instrumentConfigurations[0].isoCal === "Yes"
                                                    ? "isoCal active, perform when prompted"
                                                    : "-"
                                        }
                                    ]
                                },
                                {
                                    name: "secondSection",
                                    values: [
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .recommendedFrequency
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .recommendedTestWeight
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .accuracyClassTestWeight
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .testNominalTestWeight
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .calibrationCycle
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .actionToleranceLimit
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.onePointCalibrationDTO
                                                .warningToleranceLimit
                                        }
                                    ]
                                },
                                {
                                    name: "thirdSection",
                                    values: [
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .recommendedFrequency
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .recommendedTestWeight
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .accuracyClassTestWeight
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .calibrationCycle
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .actionToleranceLimit
                                        },
                                        {
                                            value:
                                            assessment.pageFiveDTO.repeatabilityCheckDTO
                                                .warningToleranceLimit
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div className="printSectionEnd"/>
                    <div className="printSection small">
                        <hr className="d-print-none mb-8"/>
                        <Legend/>
                    </div>
                    <div className="mb-8"></div>
                </>
            ) : (
                <CSpinner></CSpinner>
            )}
        </>
    );
};

export default Step4;
