import "./scss/style.scss";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {CContainer} from "@coreui/react";
import {useRecoilState} from "recoil";
import Header from "./components/Header";
import MobileHeader from "./components/MobileHeader";
import {Footer} from "./components/Footer";
import Step1 from "./pages/step1";
import Step2 from "./pages/step2";
import Step3 from "./pages/step3";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {showNavigationState, currentStepState, modelState} from "./states";
import ScrollToTop from "./components/ScrollToTop";
import LanguageRedirect from "./components/LanguageRedirect";
import Step4 from "./pages/step4";
import {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {getDocumentTitle} from "./utils/PrintUtils";

function App() {
    const [showNavigation] = useRecoilState(showNavigationState);
    const [currentStep] = useRecoilState(currentStepState);
    const [model] = useRecoilState(modelState);
    const location = useLocation();

    const url = location.pathname + "step1" + location.search ?? "/";
    const isSuccessPage = location.pathname.toLowerCase().endsWith("success");
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef,
        documentTitle: getDocumentTitle(model)
    });
    const isViewPage =
        location.pathname.toLowerCase().endsWith("feedbackview") ||
        location.pathname.toLowerCase().endsWith("quoteview");
    return (
        <I18nextProvider i18n={i18next}>
            <div className="c-app c-default-layout" ref={contentRef}>
                <ScrollToTop/>
                <div className="c-wrapper">
                    <div className="header-wrapper">
                        <Header
                            currentStep={currentStep}
                            showNavigation={showNavigation}
                            isSuccessPage={isSuccessPage}
                            isViewPage={isViewPage}
                            printFn={reactToPrintFn}
                        />
                    </div>
                    <div className="mobileHeader-wrapper">
                        <MobileHeader
                            isSuccessPage={isSuccessPage}
                            currentStep={currentStep}
                            isViewPage={isViewPage}
                            printFn={reactToPrintFn}
                        />
                    </div>
                    <div className="c-body">
                        <main className="c-main">
                            <CContainer>
                                <Switch>
                                    <Route path="/:lang/step1" component={() => <Step1/>}/>
                                    <Route path="/:lang/step2" component={() => <Step2/>}/>
                                    <Route path="/:lang/step3" component={() => <Step3/>}/>
                                    <Route path="/:lang/step4" component={() => <Step4 printFn={reactToPrintFn}/>}/>
                                    <Route
                                        path="\/(step[1-4]|success|feedbackView)"
                                        component={() => <LanguageRedirect/>}
                                    />
                                    <Redirect to={url} from="/"/>
                                </Switch>
                            </CContainer>
                        </main>
                    </div>
                    {!isSuccessPage && !isViewPage && (
                        <Footer currentStep={currentStep} printFn={reactToPrintFn}/>
                    )}
                </div>
            </div>
        </I18nextProvider>
    );
}

export default App;
