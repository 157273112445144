import * as React from "react";
import styles from "./BlackHeaderBar.module.scss";
import PencilMarkIcon from "../../assets/icons/pencil.svg";
import {useHistory} from "react-router-dom";

interface BlackHeaderBarProps {
    text: string | JSX.Element;
    hasButton?: boolean;
    urlToNavigate?: string;
}

const BlackHeaderBar: React.FC<BlackHeaderBarProps> = ({
                                                           text,
                                                           hasButton,
                                                           urlToNavigate
                                                       }) => {
    const history = useHistory();

    return (

        <div
            className={`text-left ${styles.blackRow} ${
                hasButton ? "" : styles.withoutCursor
            }`}
            role="button"
            data-testid="product-number-button"
            onClick={() => {
                if (hasButton) {
                    history.push(urlToNavigate);
                }
            }}
        >
            <span className={styles.headerText}>{text}</span>
            {hasButton && (
                <img
                    src={PencilMarkIcon}
                    className={`${styles.pencil} d-print-none`}
                    alt={"edit"}
                    title={"edit"}
                />
            )}
        </div>

    );
};

export default BlackHeaderBar;
