import * as React from "react";
import styles from "./RecommendedServiceSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import {Trans, useTranslation} from "react-i18next";
import {CCol, CRow} from "@coreui/react";
import InitialServices from "./InitialServices";
import LifecycleServices from "./LifecycleServices";
import {PageFourDTO, PageThreeDTO, PageTwoDTO} from "../../api";

const RecommendedServiceSection: React.FC<{
    initialServices: PageThreeDTO,
    lifecycleServices: PageFourDTO,
    config: PageTwoDTO
}> = (
    {initialServices, lifecycleServices, config}
) => {
    const {t} = useTranslation("common");
    return (
        <div className={styles.blackHeaderWrapper}>
            <BlackHeaderBar
                text={<Trans t={t} i18nKey="step4.recommendedService.headerText">Header <sup>11.1</sup></Trans>}/>
            <div className="px-3">
                <h4 className={styles.sectionTitle}>
                    {t(`step4.recommendedService.firstSection.headerText`)}
                </h4>

                <InitialServices
                    isTestPippette={config.isForTestPipettes !== "No"}
                    equipmentInstallation={true}
                    calibrationCertificate={true}
                    minWeightCertificate={true}
                    calibration={true}
                    conformityCertificate={true}
                    phEur={config.requiresComplianceEU == "Yes"}
                    usp={config.requiresComplianceUSA == "Yes"}
                    model={config.equipmentQualificationNeeded == "Yes" ? initialServices.equipmentPackageDTO.packageNumber : initialServices.installationPackageDTO.packageNumber}
                    iqOqDocumentation={initialServices.equipmentPackageDTO?.documentationIQOQ}
                    balanceConformityCertificate={initialServices.optionalServicesDTO.balanceConformityCertificate}
                    balanceTestProtocol={initialServices.optionalServicesDTO.balanceTestProtocol}
                    repeatabilityMeasurements={initialServices.optionalServicesDTO.repeatabilityMeasurements}
                />

                <h4 className={styles.secondSectionTitle + " mt-8"}>
                    {t(`step4.recommendedService.secondSection.headerText`)}
                </h4>
                <CRow>
                    <CCol>
                        <LifecycleServices
                            serviceFrequency={lifecycleServices.serviceFrequency}
                            isTestPippette={config.isForTestPipettes !== "No"}
                            equipmentMaintenance={true}
                            calibrationCertificate={true}
                            minWeightCertificate={true}
                            calibration={true}
                            conformityCertificate={true}
                            phEur={config.requiresComplianceEU == "Yes"}
                            usp={config.requiresComplianceUSA == "Yes"}
                            isBasicPackage={config.fullMaintenanceReqPos != "Yes"}
                            model={config.fullMaintenanceReqPos == "Yes" ? lifecycleServices.standardMaintanancePackageDTO.packageNumber : lifecycleServices.basicMaintanancePackageDTO.packageNumber}
                            balanceConformityCertificate={lifecycleServices.optionalServicesDTO.balanceConformityCertificate}
                            balanceTestProtocol={lifecycleServices.optionalServicesDTO.balanceTestProtocol}
                            repeatabilitMeasurements={lifecycleServices.optionalServicesDTO.repeatabilityMeasurements}
                            checkOfConformity={lifecycleServices.optionalServicesDTO.checkOfConformity}
                        />
                    </CCol>
                </CRow>
            </div>
        </div>
    );
};

export default RecommendedServiceSection;
